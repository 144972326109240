import React from "react";
import { graphql } from "gatsby";

import Layout from "@components/layout";
import Seo from "@components/seo";
import Article from "@components/article";
import Quote from "@components/quote";
import LogoSlider from "@components/logoSlider";
import PageHeading from "@components/pageheading";
import PageLead from "@components/pageLead";
import PageBody from "@components/pageBody";
import PageImage from "@components/pageImage";
import Card from "@components/card";
import Person from "@components/person";
import CtaStrip from "@components/ctaStrip";
import StatsStrip from "@components/statsStrip";
import LinkedCard from "@components/linkedCard";

const Page = ({ data }) => {
  const pageData = data.strapiPage;

  // console.log(pageData.heroImage.file.url);

  // console.log(pageData);

  return (
    <Layout pageData={pageData}>
      <Seo
        title={pageData.heroHeading}
        description={pageData.heroText}
        featuredImage={pageData.heroImage.file.url}
      />

      {pageData.content.map((content, index) => (
        <div key={index}>
          {content.__typename == "StrapiComponentContentArticleGrid" ? (
            <div className="relative bg-gray-50 pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8">
              <div className="relative max-w-7xl mx-auto">
                <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                  {content.articlegroup.map((article, index) => (
                    <Article
                      key={index}
                      title={article.article.articleTitle}
                      image={article}
                      shortText={article.article.articleShorttext}
                      altText={article.article.articleImage.alternativeText}
                      themeColor={article.article.themeColor}
                      slug={article.article.slug}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : null}
          {content.__typename == "StrapiComponentContentQuote" ? (
            <Quote
              quoteName={content.quoteName}
              quoteText={content.quoteText}
              quoteTitle={content.quoteTitle}
              themeColor={content.themeColor}
              quoteColor={content.quoteColor}
              quoteTextColor={content.quoteTextColor}
            />
          ) : null}
          {content.__typename == "StrapiComponentContentLogoSlider" ? (
            <LogoSlider
              logoSliderTitle={content.logoSliderTitle}
              buttonText={content.buttonText}
              buttonLInk={content.buttonLInk}
              logos={content.logos}
              alternativeText={content.alternativeText}
            />
          ) : null}
          {content.__typename == "StrapiComponentPagePageHeading" ? (
            <PageHeading pageHeadingText={content.pageHeadingText} />
          ) : null}
          {content.__typename == "StrapiComponentPagePageLead" ? (
            <PageLead pageLeadText={content.pageLeadText} />
          ) : null}
          {content.__typename == "StrapiComponentPagePageBody" ? (
            <PageBody
              pageBodyText={content.pageBodyText}
              finalBodyText={content.finalBodyText}
              noHeading={content.noHeading}
            />
          ) : null}
          {content.__typename == "StrapiComponentPagePageImage" ? (
            <PageImage
              pageBodyImage={content.pageBodyImage}
              pageImageCaption={content.pageImageCaption}
              finalpageImage={content.finalpageImage}
            />
          ) : null}
          {content.__typename == "StrapiComponentContentValueGrid" ? (
            <div className={"bg-" + content.themeColorGroup}>
              <div className="pb-6 pt-12 max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="pb-12 text-3xl text-leading font-bold text-white font-heading">
                  {content.groupTitle}
                </h2>
                <div
                  className={
                    "max-w-lg mx-auto grid gap-5 lg:grid-cols-" +
                    content.numberColumns +
                    " lg:max-w-none bg-" +
                    content.themeColorGroup
                  }
                >
                  {content.cardgroup.map((card, index) => (
                    <Card
                      key={index}
                      cardTitle={card.card.cardTitle}
                      cardImage={card.card.cardImage}
                      cardRichText={card.card.cardRichText}
                      cardLink={card.card.cardLink}
                      cardLinkExternal={card.card.cardLinkExternal}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : null}
          {content.__typename == "StrapiComponentContentPersonGrid" ? (
            <div
              className={
                content.themeColorPeople
                  ? "bg-" + content.themeColorPeople
                  : "bg-white"
              }
            >
              <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-8">
                <div>
                  <h2
                    className={
                      content.themeColorPeople
                        ? "text-3xl font-extrabold tracking-normal sm:text-4xl pt-3 pb-9 font-heading text-white"
                        : "text-3xl font-extrabold tracking-normal sm:text-4xl pt-3 pb-9 font-heading"
                    }
                  >
                    {content.peopleTitle}
                  </h2>
                </div>

                <ul
                  role="list"
                  className="space-y-12 grid-cols  lg:grid lg:grid-cols-3 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
                >
                  {content.personGroup.map((person, index) => (
                    <li key={index}>
                      <Person
                        personName={person.person.personName}
                        personTitle={person.person.personTitle}
                        personBioText={person.person.personBioText}
                        personImage={person.person.personImage}
                        theme={content.themeColorPeople}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {content.__typename == "StrapiComponentContentCtaStrip" ? (
            <CtaStrip
              ctaLeadText={content.ctaLeadText}
              ctaBodyText={content.ctaBodyText}
              ctaStripTitle={content.ctaStripTitle}
              ctaStripBgColor={content.ctaStripBgColor}
              ctaStripButtonText={content.ctaStripButtonText}
              ctaStripButtonLink={content.ctaStripButtonLink}
              ctaStripButtonColor={content.ctaStripButtonColor}
            />
          ) : null}
          {content.__typename == "StrapiComponentContentStatsStrip" ? (
            <StatsStrip
              statText1={content.statText1}
              statText2={content.statText2}
              statText3={content.statText3}
              statNumber1={content.statNumber1}
              statNumber2={content.statNumber2}
              statNumber3={content.statNumber3}
              statsTitle={content.statsTitle}
              statsLeadText={content.statsLeadText}
              statsBgColor={content.statsBgColor}
              statsTextColor={content.statsTextColor}
              statImage1={content.statImage1}
              statImage2={content.statImage2}
              statImage3={content.statImage3}
            />
          ) : null}

          {content.__typename == "StrapiComponentContentLinkedCardGrid" ? (
            <div className="relative bg-gray-50 pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8">
              <div className="relative max-w-7xl mx-auto">
                <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                  {content.linkedcardgroup.map((article, index) => (
                    <LinkedCard
                      key={index}
                      linkedCardText={article.linked_card.linkedCardText}
                      linkedCardTitle={article.linked_card.linkedCardTitle}
                      linkedCardPageLink={
                        article.linked_card.linkedCardPageLink
                      }
                      linkedCardThemeColor={
                        article.linked_card.linkedCardThemeColor
                      }
                      linkedCardImage={article.linked_card.linkedCardImage}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query ($pageSlug: String!) {
    strapiPage(slug: { eq: $pageSlug }) {
      title
      themeColor
      heroText
      heroHeading
      heroButton1Link
      heroButton1Text
      heroButton2Link
      heroButton2Text
      heroButtonVideo
      themeTitleColor
      heroImage {
        alternativeText
        file {
          url
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: AUTO
            )
          }
        }
      }
      ... on StrapiPage {
        content {
          ... on StrapiComponentContentArticleGrid {
            __typename
            articlegroup {
              id
              article {
                id
                articleTitle
                articleShorttext
                articleLongText
                themeColor
                slug
                articleImage {
                  alternativeText
                  file {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: AUTO
                      )
                    }
                  }
                }
              }
            }
          }
          ... on StrapiComponentContentQuote {
            __typename
            quoteText
            quoteName
            quoteTitle
            themeColor
            quoteColor
            quoteTextColor
          }

          ... on StrapiComponentContentLogoSlider {
            __typename
            logoSliderTitle
            buttonLInk
            buttonText
            logos {
              alternativeText
              file {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
          }
          ... on StrapiComponentPagePageHeading {
            __typename
            pageHeadingText
          }
          ... on StrapiComponentPagePageLead {
            __typename
            pageLeadText
          }
          ... on StrapiComponentPagePageBody {
            __typename
            pageBodyText
            finalBodyText
            noHeading
          }
          ... on StrapiComponentPagePageImage {
            pageImageCaption
            finalpageImage
            __typename
            pageBodyImage {
              alternativeText
              file {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
          }
          ... on StrapiComponentContentValueGrid {
            __typename
            themeColorGroup
            numberColumns
            groupTitle
            cardgroup {
              id
              card {
                cardTitle
                cardRichText
                cardLink
                cardLinkExternal
                cardImage {
                  alternativeText
                  file {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: AUTO
                      )
                    }
                  }
                }
              }
            }
          }
          ... on StrapiComponentContentPersonGrid {
            __typename
            id
            themeColorPeople
            peopleTitle
            personGroup {
              person {
                id
                personName
                personTitle
                personBioText
                personImage {
                  alternativeText
                  file {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: AUTO
                      )
                    }
                  }
                }
              }
            }
          }
          ... on StrapiComponentContentCtaStrip {
            __typename
            id
            ctaLeadText
            ctaBodyText
            ctaStripTitle
            ctaStripBgColor
            ctaStripButtonText
            ctaStripButtonLink
            ctaStripButtonColor
          }
          ... on StrapiComponentContentStatsStrip {
            __typename
            id
            statText1
            statText2
            statText3
            statNumber1
            statNumber2
            statNumber3
            statsTitle
            statsLeadText
            statsBgColor
            statsTextColor
            statImage1 {
              alternativeText
              file {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
            statImage2 {
              alternativeText
              file {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
            statImage3 {
              alternativeText
              file {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
          }

          ... on StrapiComponentContentLinkedCardGrid {
            __typename
            linkedcardgroup {
              linked_card {
                linkedCardText
                linkedCardTitle
                linkedCardPageLink
                linkedCardThemeColor
                linkedCardImage {
                  alternativeText
                  file {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: AUTO
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
